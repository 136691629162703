export const PROOF_ACCESS_PERMISSIONS = {
  OWNED_ONLY: 1,
  TEAMS_ONLY: 2,
  EVERYTHING: 3,
  NONE: 4,
};

export const DOC_TYPES = {
  VIDEO : "video",
  HTML : "html",
  AUDIO : "audio",
  IMAGE : "image",
  DOCUMENT : "document",
  WEB_URL : "web_url"
}

export const ANNOTATION_TYPES = {
  DRAW : 'draw',
}

export const PROOF_VIEW_MODES = {
  APPROVER_MODE: 0,
  PROOF_OWNER_MODE: 1,
  COMMENTER_MODE: 2,
}

export const TYPES_ENUM = {
  STRING: 'string',
  FUNCTION: 'function'
}

export const DEFAULT_ACCOUNT_NAME = "Ashore";
export const DEFAULT_ACCOUNT_LOGO = "img/logo-large.svg"
